<template>
    <div>
        <v-select
            @search="fetchOptions"
            @open="onOpen"
            :filterable='filterable'
            :options="options"
            :label='label'
            :value='myValue'
            :reduce="country => country.code"
            v-on:input='updateValue'
            :multiple='multiple'
            :closeOnSelect='closeOnSelect'
        >
        </v-select>
    </div>
</template>

<script>
import BaseRemoteSelect from './BaseRemoteSelect.vue';
const countryList = require('country-list');

export default {
    extends: BaseRemoteSelect,
    data: function() {
        return {
            label: 'name'
        }
    },
    methods: {
        load(_apiClient, search) {
            return new Promise(resolve => {
                search = search.toLowerCase();
                resolve({data: countryList.getData().filter(item => !search || item.code.toLowerCase().includes(search) || item.name.toLowerCase().includes(search) )});
            });
        },
        loadSingle(_apiClient, id) {
            return new Promise(resolve => {
                resolve(countryList.getData().find(item => item.code == id));
            });
        }
    }
}
</script>
